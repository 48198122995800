import styled from 'styled-components'

const Superscript = styled.p`
  font-size: 180px;
  ${props => (props.fontSize ? `font-size: ${props.fontSize};` : null)}
  line-height: 150px;
  font-family: 'Inter', sans-serif;
  font-weight: 900;
  letter-spacing: -5px;
  color: white;
  margin-left: -7px;
  ::-moz-selection {
    text-shadow: ${props =>
      props.shadow
        ? props.shadow
        : `1px 1px red, 2px 2px red, 3px 3px red, 4px 4px red, 5px 5px red,
      6px 6px red, 7px 7px red, 8px 8px red, 9px 9px red, 10px 10px red`};
  }
  ::selection {
    text-shadow: ${props =>
      props.shadow
        ? props.shadow
        : `1px 1px red, 2px 2px red, 3px 3px red, 4px 4px red, 5px 5px red,
      6px 6px red, 7px 7px red, 8px 8px red, 9px 9px red, 10px 10px red`};
  }
  @media (max-width: 1000px) {
    font-size: 18vw;
    ${props => (props.mobileSize ? `font-size: ${props.mobileSize};` : null)}
    line-height: 15vw;
    letter-spacing: -0.5vw;
    margin-left: -0.7vw; // don't let white text bleed to the left of the red logo tag
    ::-moz-selection {
      text-shadow: ${props =>
        props.mobileshadow
          ? props.mobileshadow
          : `0.1vw 0.1vw red, 0.2vw 0.2vw red, 0.3vw 0.3vw red,
        0.4vw 0.4vw red, 0.5vw 0.5vw red, 0.6vw 0.6vw red, 0.7vw 0.7vw red,
        0.8vw 0.8vw red, 0.9vw 0.9vw red, 1vw 1vw red`};
    }
    ::selection {
      text-shadow: ${props =>
        props.mobileshadow
          ? props.mobileshadow
          : `0.1vw 0.1vw red, 0.2vw 0.2vw red, 0.3vw 0.3vw red,
        0.4vw 0.4vw red, 0.5vw 0.5vw red, 0.6vw 0.6vw red, 0.7vw 0.7vw red,
        0.8vw 0.8vw red, 0.9vw 0.9vw red, 1vw 1vw red`};
    }
  }
`
export default Superscript
